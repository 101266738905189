import Image, { ImageProps, ImageLoaderProps } from 'next/future/image'

const loader = ({ src, width, quality }: ImageLoaderProps) => {
  if (src.startsWith('http') && new URL(src).pathname.startsWith('/assets/')) {
    const path = new URL(src).pathname
    return `${path}?width=${width}&quality=${quality || 75}&format=webp`
  } else {
    return `/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${
      quality || 75
    }`
  }
}

const NextImage = (props: ImageProps) => {
  return <Image {...props} alt={props.alt} loader={loader} />
}

export default NextImage
export type { ImageProps }
