export const HOME_PAGE_PATH = '/'
export const BIKE_TOUR_PATH = '/product-detail'
export const BLOG_PATH = '/post'
export const PRODUCT_PATH = '/products'
export const GALLERY_PATH = '/gallery'
export const ABOUT_US_PAGE_PATH = '/about-us'
export const FAQS_PATH = '/faqs'
export const CONTACTS_PATH = '/contacts'
export const TERMS_AND_CONDITIONS_PATH = '/terms-and-conditions'
export const SEARCH_PAGE_PATH = '/search'
export const COLLECTIONS_PAGE_PATH = '/collections'
export const CATEGORIES_PAGE_PATH = '/categories'

export const NAVIGATION = [
  {
    text: 'About Us',
    url: ABOUT_US_PAGE_PATH,
  },
  {
    text: 'Bike tours',
    url: BIKE_TOUR_PATH,
  },
  {
    text: 'Gallery',
    url: GALLERY_PATH,
  },
  {
    text: 'Blog',
    url: BLOG_PATH,
  },
  {
    text: 'Bike Rentals',
    url: '/collections/bikes-rental-cid4',
  },
]

export const LEFT_NAVIGATION = [
  {
    text: 'About Us',
    url: ABOUT_US_PAGE_PATH,
  },
  {
    text: 'Bike tours',
    url: BIKE_TOUR_PATH,
  },
  {
    text: 'Gallery',
    url: GALLERY_PATH,
  },
  {
    text: 'Blog',
    url: BLOG_PATH,
  },
]

export const RIGHT_NAVIGATION = [
  {
    text: 'FAQS',
    url: FAQS_PATH,
  },
  {
    text: 'Contacts',
    url: CONTACTS_PATH,
  },
  {
    text: 'Terms and conditions',
    url: TERMS_AND_CONDITIONS_PATH,
  },
]
