import {
  FALLBACK_IMAGE_PATH,
  THUMBNAIL_SM,
  THUMBNAIL_MD,
  THUMBNAIL_LG,
  MARQUEE_GALLERY,
  FULL,
  FOURK,
} from '@/ts/constant'
import { Directus_Files } from '@/ts/graphqlschema'

const ImageOptionsMap = new Map()
ImageOptionsMap.set('sm', THUMBNAIL_SM)
ImageOptionsMap.set('md', THUMBNAIL_MD)
ImageOptionsMap.set('lg', THUMBNAIL_LG)
ImageOptionsMap.set('fourk', FOURK)
ImageOptionsMap.set('full', FULL)
ImageOptionsMap.set('marquee_gal', MARQUEE_GALLERY)

const DEFAULT_URL_PATH = '/images/heading-image.jpeg'

export function getCMSImageUrl(
  thumbnail?: Directus_Files | null,
  option?:
    | 'sm'
    | 'md'
    | 'lg'
    | 'home-slide-show'
    | 'ogImage'
    | 'marquee_gal'
    | 'full'
    | 'fourk',
  format?: 'webp' | 'png' | 'jpg' | 'jpeg'
): string {
  if (!thumbnail) return DEFAULT_URL_PATH
  const imageOption = option
    ? ImageOptionsMap.get(option)
    : ImageOptionsMap.get('md') // md is a default option
  const thumbnailUrl: string = thumbnail.filename_disk
    ? `${process.env.NEXT_PUBLIC_PUBLIC_URL}/assets/${
        thumbnail.filename_disk
      }?${imageOption}&format=${format || 'webp'}`
    : FALLBACK_IMAGE_PATH
  return thumbnailUrl
}
