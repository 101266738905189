import { IMetadata } from '@/ts/models'

export const DOMAIN = 'https://mrbikersaigon.com'
export const HOMEPAGE_TITLE = 'Mr.Biker Saigon'
export const HOMEPAGE_DESCRIPTION =
  'Mr Biker Saigon has solidified its position as a premier provider of adventure cycling tours in Vietnam. With a strong commitment to sustainable travel, we go beyond just exploring the stunning landscapes, striving to leave a positive impact on the communities we encounter'
export const FAVICON = '/favicon.ico'
export const HOMEPAGE_OG_IMAGE = '/og-image.jpeg'
export const HOMEPAGE_OG_TITLE = HOMEPAGE_TITLE
export const HOMEPAGE_OG_DESCRIPTION = HOMEPAGE_DESCRIPTION
export const PAGE_NAME = 'MrBikerSaigon'

export const DEFAULT_METADATA: IMetadata = {
  // Common metadata
  title: HOMEPAGE_TITLE,
  // Article Specific Metadata
  keywords: [],
  description: HOMEPAGE_DESCRIPTION,
  // Open Graph Metadata
  ogType: 'website',
  ogTitle: HOMEPAGE_TITLE,
  ogDescription: HOMEPAGE_DESCRIPTION,
  ogImage: HOMEPAGE_OG_IMAGE,
  ogSiteName: HOMEPAGE_TITLE,
  ogUrl: DOMAIN,
  // Twitter Card Metadata
  twitterUrl: DOMAIN,
  twitterTitle: HOMEPAGE_TITLE,
  twitterDescription: HOMEPAGE_DESCRIPTION,
  twitterImageSrc: HOMEPAGE_OG_IMAGE,
  locale: 'en_US',
  imageAlt: 'Mr Biker Saigon Image Alternative Text',
}
