import { createContext, ReactNode, useMemo, useState } from 'react'
import { IMetadata } from '@/ts/models'
import PageHead from '@/components/layout/head'

import dynamic from 'next/dynamic'

import { LazyMotion, domAnimation } from 'framer-motion'
import { ISectionGallery } from '@/ts/sections'
import useSWR, { useSWRConfig } from 'swr'
import { AnnouncementModal } from '@/components/modal'
import {
  Components,
  Get_Cms_SettingsQuery,
  Get_Cms_SettingsQueryVariables,
  Get_Section_Gallery_By_IdQuery,
  Get_Section_Gallery_By_IdQueryVariables,
} from '@/ts/graphqlschema'
import { EComponentsName } from '@/ts/constant'
import { fetchApi } from '@/utils/api'
import { m } from 'framer-motion'

const HighLightGallery = dynamic(
  () => import('@/components/common/highlight-gallery')
)

type TLayoutProps = {
  metadata?: IMetadata
  title?: string
  children: ReactNode
  notUseScrollAnimation?: boolean
  notHaveFixedPos?: boolean
  shouldRenderHighlight?: boolean
  disabledGalleryImages?: boolean
  shouldRenderContacts?: boolean
  section_gallery?: ISectionGallery | null
  disabledSEO?: boolean
}

export const LayoutContext = createContext(undefined)
function Layout({
  metadata,
  children,
  disabledGalleryImages,
  disabledSEO,
}: TLayoutProps) {
  const { cache } = useSWRConfig()
  const [isOpenModal] = useState<boolean>(true)

  const { data } = useSWR(
    ['/api/sectionGallery/1'],
    url =>
      !cache.get('get_gallery_by_id')?.data
        ? fetchApi<
            Get_Section_Gallery_By_IdQuery,
            Get_Section_Gallery_By_IdQueryVariables
          >(url)
        : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const galleryImages = useMemo(
    () =>
      cache.get('get_gallery_by_id')?.section_gallery_by_id ||
      data?.section_gallery_by_id,
    [cache, data]
  )

  const { data: generalSettingsData } = useSWR(
    [
      '/api/cmsSettings',
      {
        filter: {
          name: {
            _eq: EComponentsName.Modal,
          },
        },
      },
    ],
    (url, value) =>
      fetchApi<Get_Cms_SettingsQuery, Get_Cms_SettingsQueryVariables>(
        url,
        value
      ),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const modalDataResults =
    generalSettingsData?.cms_settings?.components?.filter(
      data => data.components_id
    )

  return (
    <LazyMotion features={domAnimation} strict>
      <PageHead metadata={metadata} disabledSEO={disabledSEO} />
      <m.div
        initial={{ x: 0, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 0, opacity: 0 }}
        transition={{
          duration: 2,
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        <main className="">
          {children}
          <section>
            {disabledGalleryImages && galleryImages && (
              <HighLightGallery images={galleryImages} />
            )}
          </section>
          {/*{shouldRenderContacts && <ContactsSection />}*/}
        </main>
        {/*<Footer />*/}
        {modalDataResults?.length > 0 && (
          <AnnouncementModal
            isOpen={isOpenModal}
            modalData={modalDataResults[0].components_id as Components}
          />
        )}
      </m.div>
    </LazyMotion>
  )
}

Layout.defaultProps = {
  isFixedHeader: true,
}

export default Layout
